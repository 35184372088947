import { render, staticRenderFns } from "./GoodPromote.vue?vue&type=template&id=19668153&scoped=true"
import script from "./GoodPromote.vue?vue&type=script&lang=js"
export * from "./GoodPromote.vue?vue&type=script&lang=js"
import style0 from "./GoodPromote.vue?vue&type=style&index=0&id=19668153&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19668153",
  null
  
)

export default component.exports